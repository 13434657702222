<template>
  <div>
    <v-card>
      <v-card-title>View Withdrawal</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="ml-2 mt-2 mr-2"
        >
          <v-card class="mt-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Withdrawal Details
                </div>

                <v-card-text>
                  <v-autocomplete
                    v-model="model.withdrawalTypeCode"
                    label="Withdrawal Type"
                    :items="withdrawalTypeFilterItems"
                    item-text="text"
                    item-value="value"
                    readonly
                    disabled
                    outlined
                    dense
                    hide-details="auto"
                  >
                  </v-autocomplete>
                </v-card-text>

                <v-card-text>
                  Creation Date: {{ formatDateAndTime(model.createdAt) }}
                </v-card-text>

                <v-card-text>
                  Payment Date: {{ model.paymentDate ? formatDateAndTime(model.paymentDate) : '' }}
                </v-card-text>

                <v-card-text>
                  Amount: {{ formatCurrency(model.amount) }} {{ model.currencyCode }}
                </v-card-text>

                <v-card-text>
                  Status:
                  <v-chip
                    small
                    :color="resolveStatusVariant(model.statusCode)"
                    :class="`${resolveStatusVariant(model.statusCode)}--text`"
                    class="v-chip-light-bg font-weight-semibold text-capitalize"
                  >
                    {{ model.statusCode }}
                  </v-chip>
                </v-card-text>

                <v-card-text>
                  <v-textarea
                    v-model="model.comments"
                    outlined
                    readonly
                    disabled
                    label="Comments"
                    placeholder="Comments"
                    hide-details="auto"
                  ></v-textarea>
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card class="mt-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Settlements:
                </div>

                <v-card-text>
                  <v-data-table
                    :headers="settlementColumns"
                    :items="settlements"
                    :items-per-page="5"
                    item-key="settlementCode"
                  >
                    <!-- UID -->
                    <template #[`item.settlementCode`]="{ item }">
                      <a
                        href="#"
                        @click="viewSettlement(item.awsKey)"
                      >{{ item.settlementCode }}</a>
                    </template>

                    <!-- Created At  -->
                    <template #[`item.createdAt`]="{ item }">
                      {{ formatDate(item.createdAt, { dateStyle: 'short' }) }}
                    </template>

                    <!-- Date From -->
                    <template #[`item.startDate`]="{ item }">
                      {{ formatDate(item.startDate, { dateStyle: 'short' }) }}
                    </template>

                    <!-- Date To -->
                    <template #[`item.endDate`]="{ item }">
                      {{ formatDate(item.endDate, { dateStyle: 'short' }) }}
                    </template>

                    <!-- Opening Balance -->
                    <template #[`item.balanceToBeSettled`]="{ item }">
                      {{ formatCurrency(item.balanceToBeSettled) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card
            v-if="model.withdrawalTypeCode == withdrawalTypeLocal.BANK_TRANSFER"
            class="mt-5"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Bank Details
                </div>

                <v-card-text>
                  <v-text-field
                    v-model="model.beneficiaryName"
                    readonly
                    disabled
                    outlined
                    dense
                    label="Beneficiary Name"
                    placeholder="Beneficiary Name"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.beneficiaryAddress"
                    readonly
                    disabled
                    outlined
                    dense
                    label="Beneficiary Address"
                    placeholder="Beneficiary Address"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.bankName"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Bank Name"
                    placeholder="Bank Name"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.accountNumber"
                    readonly
                    disabled
                    outlined
                    dense
                    label="Account Number"
                    placeholder="Account Number"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.swift"
                    readonly
                    disabled
                    outlined
                    dense
                    label="Switf"
                    placeholder="Swift"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.bankAddress"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Bank Address"
                    placeholder="Bank Address"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-autocomplete
                    v-model="model.accountCurrencyCode"
                    readonly
                    disabled
                    placeholder="Bank Account Currency"
                    label="Bank Account Currency"
                    :items="currencyFilterItems"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card
            v-if="model.withdrawalTypeCode == withdrawalTypeLocal.CRYPTO"
            class="mt-5"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Crypto Details
                </div>

                <v-card-text>
                  <v-autocomplete
                    v-model="model.cryptoCurrencyCode"
                    readonly
                    disabled
                    placeholder="Currency"
                    label="Currency"
                    :items="cryptoCurrencyFilterItems"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-card-text>

                <v-card-text>
                  <v-autocomplete
                    v-model="model.cryptoNetworkId"
                    readonly
                    disabled
                    placeholder="Network"
                    label="Network"
                    :items="cryptoNetworkFilterItems"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.walletAddress"
                    readonly
                    disabled
                    outlined
                    dense
                    label="Wallet Address"
                    placeholder="Wallet Address"
                    hide-details="auto"
                  ></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field
                    v-model="model.cryptoAmount"
                    outlined
                    dense
                    label="Amount"
                    placeholder="Amount"
                    hide-details="auto"
                    readonly
                    disabled
                    type="number"
                  ></v-text-field>
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-btn
            color="secondary"
            outlined
            class="ml-5 mt-5 mb-10"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-toast ref="vtoast"></v-toast>
  </div>
</template>

<script>
import { mdiClose, mdiCalendar } from '@mdi/js'
import {
  onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import {
  required, emailValidator, numbersValidator, decimalValidator,
} from '@core/utils/validation'
import { formatCurrency, formatDateAndTime, formatDate } from '@core/utils/filter'
import store from '@/store'
import withdrawalType from '@/constants/withdrawal-type'
import withdrawalStatus from '@/constants/withdrawal-status'
import withdrawalStoreModule from '@/views/finance/withdrawals/storeModule'
import settlementStoreModule from '@/views/finance/settlements/storeModule'
import VToast from '@/components/VToast.vue'
import router from '@/router'

export default {
  components: { VToast },
  setup() {
    const STORE_MODULE_NAME_WITHDRAWALS = 'finance-withdrawals'
    const STORE_MODULE_NAME_SETTLEMENTS = 'finance-settlements'

    if (!store.hasModule(STORE_MODULE_NAME_WITHDRAWALS)) {
      store.registerModule(STORE_MODULE_NAME_WITHDRAWALS, withdrawalStoreModule)
    }

    if (!store.hasModule(STORE_MODULE_NAME_SETTLEMENTS)) {
      store.registerModule(STORE_MODULE_NAME_SETTLEMENTS, settlementStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME_WITHDRAWALS)) {
        store.unregisterModule(STORE_MODULE_NAME_WITHDRAWALS)
      }

      if (store.hasModule(STORE_MODULE_NAME_SETTLEMENTS)) {
        store.unregisterModule(STORE_MODULE_NAME_SETTLEMENTS)
      }
    })

    const settlements = ref([])
    const merchantFilterItems = ref([])
    const statusFilterItems = ref([])
    const withdrawalTypeFilterItems = ref([])
    const currencyFilterItems = ref([])
    const cryptoCurrencyFilterItems = ref([])
    const cryptoNetworkFilterItems = ref([])
    const vtoast = ref(null)
    const model = ref({})
    const valid = ref(false)
    const form = ref(null)
    const loading = ref(false)
    const showApplyOnModalDate = ref(false)
    const withdrawalTypeLocal = ref(withdrawalType)
    const isConfirmDialogVisible = ref(false)

    const settlementColumns = [
      { text: 'UID', value: 'settlementCode', sortable: false },
      {
        text: 'CREATED AT', value: 'createdAt', sortable: false, align: 'center',
      },
      {
        text: 'SHOP', value: 'shopName', sortable: false, align: 'center',
      },
      {
        text: 'FROM', value: 'startDate', sortable: false, align: 'center',
      },
      {
        text: 'TO', value: 'endDate', sortable: false, align: 'center',
      },
      {
        text: 'CURRENCY', value: 'currencyCode', sortable: false, align: 'center',
      },
      {
        text: 'AMOUNT', value: 'balanceToBeSettled', sortable: false, align: 'right',
      },
    ]

    const validate = () => {
      if (form !== null) {
        form.value.validate()
      }
    }

    const fetchConfig = () => {
      loading.value = true
      store
        .dispatch('finance-withdrawals/fetchCreateConfig')
        .then(response => {
          const config = response.data
          merchantFilterItems.value = config.merchants
          currencyFilterItems.value = config.currencies
          withdrawalTypeFilterItems.value = config.withdrawalTypes
          cryptoCurrencyFilterItems.value = config.cryptoCurrencies
          cryptoNetworkFilterItems.value = config.cryptoNetworks
          statusFilterItems.value = config.statusTypes
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const fetch = () => {
      loading.value = true

      const { id } = router.currentRoute.params

      store
        .dispatch('finance-withdrawals/fetch', id)
        .then(response => {
          model.value = response.data
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const fetchRelatedSettlements = () => {
      loading.value = true

      const { id } = router.currentRoute.params

      store
        .dispatch('finance-withdrawals/fetchRelatedSettlements', id)
        .then(response => {
          settlements.value = response.data
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const viewSettlement = key => {
      store
        .dispatch('finance-settlements/getPresignedUrl', { key })
        .then(response => {
          const url = response.data
          window.open(url)
        })
        .catch(error => error).finally(() => {
          loading.value = false
        })
    }

    const showConfirmationDialog = () => {
      isConfirmDialogVisible.value = true
    }

    const saveChanges = () => {
      if (valid.value) {
        loading.value = true

        store
          .dispatch('finance-withdrawals/update', model.value)
          .then(() => {
            vtoast.value.show([{ message: 'Your changes have been saved', code: '000' }], 'info')
          })
          .catch(error => {
            vtoast.value.show(error.response.data.errors, 'error')
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    const deleteWithdrawal = () => {
      const { id } = router.currentRoute.params

      loading.value = true

      store
        .dispatch('finance-withdrawals/delete', id)
        .then(() => {
          vtoast.value.show([{ message: 'The withdrawal has been deleted', code: '000' }], 'info')

          router.back()
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const resolveStatusVariant = status => {
      if (status === withdrawalStatus.PENDING) return 'secondary'
      if (status === withdrawalStatus.COMPLETED) return 'primary'
      if (status === withdrawalStatus.CANCELLED) return 'warning'

      return 'primary'
    }

    onMounted(() => {
      fetchConfig()
      fetch()
      fetchRelatedSettlements()
    })

    return {
      router,
      vtoast,
      isConfirmDialogVisible,
      withdrawalTypeLocal,
      withdrawalStatus,
      form,
      settlementColumns,
      merchantFilterItems,
      currencyFilterItems,
      statusFilterItems,
      withdrawalTypeFilterItems,
      cryptoCurrencyFilterItems,
      cryptoNetworkFilterItems,
      loading,
      valid,
      settlements,
      model,
      showApplyOnModalDate,
      viewSettlement,
      resolveStatusVariant,
      fetch,
      fetchRelatedSettlements,
      validate,
      fetchConfig,
      showConfirmationDialog,
      deleteWithdrawal,
      saveChanges,
      formatCurrency,
      formatDateAndTime,
      formatDate,

      // validation
      validators: {
        required,
        emailValidator,
        numbersValidator,
        decimalValidator,
      },
      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang='scss' scoped>
.align-center {
  display: table-cell;
}
</style>
